import(/* webpackMode: "eager" */ "/home/nlutest_user/datacentrix-software/nlu-platform/admin-dashboard/node_modules/.pnpm/next@15.0.3_@babel+core@7.25.2_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_jsplyc6fekddiswa2f47yv4uby/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/home/nlutest_user/datacentrix-software/nlu-platform/admin-dashboard/node_modules/.pnpm/next@15.0.3_@babel+core@7.25.2_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_jsplyc6fekddiswa2f47yv4uby/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/home/nlutest_user/datacentrix-software/nlu-platform/admin-dashboard/node_modules/.pnpm/next@15.0.3_@babel+core@7.25.2_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_jsplyc6fekddiswa2f47yv4uby/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/nlutest_user/datacentrix-software/nlu-platform/admin-dashboard/node_modules/.pnpm/next@15.0.3_@babel+core@7.25.2_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_jsplyc6fekddiswa2f47yv4uby/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/home/nlutest_user/datacentrix-software/nlu-platform/admin-dashboard/node_modules/.pnpm/next@15.0.3_@babel+core@7.25.2_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_jsplyc6fekddiswa2f47yv4uby/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/nlutest_user/datacentrix-software/nlu-platform/admin-dashboard/node_modules/.pnpm/next@15.0.3_@babel+core@7.25.2_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_jsplyc6fekddiswa2f47yv4uby/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/home/nlutest_user/datacentrix-software/nlu-platform/admin-dashboard/node_modules/.pnpm/next@15.0.3_@babel+core@7.25.2_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_jsplyc6fekddiswa2f47yv4uby/node_modules/next/dist/lib/metadata/metadata-boundary.js");
